
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ElCurrencyInput from "@/components/CurrencyInput.vue";
import store from "@/store";

export default defineComponent({
  name: "request-payout-modal",
  components: { ElCurrencyInput },
  emits: ["added_payout"],
  data() {
    return {
      affiliateId: "",
      affiliateList: [],
      key: "",
      errors: {
        amount: "",
      },
      formData: {
        amount: 0,
        paymentMethod: 0,
      },
    };
  },
  computed: {
    showAffiliateSelector() {
      return (
        store.getters.isUserAuthenticated &&
        store.getters.currentUser.moderator == 1
      );
    },
  },
  methods: {
    closeModal() {
      if (this.newPayoutModalRef) {
        hideModal(this.newPayoutModalRef);
      }
    },
    requestPayout() {
      if (this.formData.amount == 0) {
        this.errors.amount = "Amount must be a valid number";
      }
      ApiService.post("payout", {
        amount: this.amount,
        paymentMethod: this.paymentMethod,
        affiliateId: this.affiliateId,
      })
        .then((data) => {
          this.$emit("added_payout");
          Swal.fire({
            text: "Your payout has been successfully requested. Please wait up until 48hs to be processed.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          });
          this.closeModal();
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            text: err.response ? err.response.data.message : err,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Got it!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          return false;
        });
    },
  },
  mounted() {
    ApiService.get("affiliates").then((result) => {
      this.affiliateList = result.data.data;
      if (this.affiliateList.length == 1) {
        this.affiliateId = (this.affiliateList[0] as any).id;
      }
    });

    const element = document.getElementById(
      "request-payout-modal"
    ) as HTMLElement;
    element.addEventListener("hide.bs.modal", () => {
      this.amount = null;
      this.paymentMethod = null;
    });
    element.addEventListener("show.bs.modal", () => {
      this.amount = null;
      this.paymentMethod = null;
    });

  },
  setup() {
    const newPayoutModalRef = ref<null | HTMLElement>(null);
    const store = useStore();

    const amount = ref();
    const paymentMethod = ref();

    const paymentMethods = [
      {
        name: "Bank Wire",
        id: 1,
        icon: "bi bi-bank",
      },
      {
        name: "USDT",
        id: 2,
        icon: "bi currency-bitcoin",
      }
    ];

    return {
      newPayoutModalRef,
      store,
      paymentMethods,
      amount,
      paymentMethod,
    };
  },
});
