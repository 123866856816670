<template>
  <div class="el-input">
    <div class="el-input__wrapper">
      <input class="el-input__inner" ref="inputRef" v-model="formattedValue"/>
    </div>
  </div>

</template>

<style scoped>
.el-input__inner {
   box-shadow: 0 0 0 1px var(--el-select-border-color-hover) inset;
}
</style>
<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "ElCurrencyInput",
  props: {
    options: Object,
  },
  mounted() {
    const element = document.getElementById(
        "request-payout-modal"
    );
    element.addEventListener("hide.bs.modal", () => {
      this.formattedValue = null;
    });
    element.addEventListener("show.bs.modal", () => {

      this.formattedValue = null;
    });

  },
  setup(props) {

    const { inputRef, formattedValue } = useCurrencyInput(props.options);
    return { inputRef, formattedValue };
  },
};
</script>
